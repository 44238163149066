<div class="w-full h-full">
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M29.13 17.84C28.77 17.33 28.17 17 27.5 17L16.5 17.01C15.4 17.01 14.5 17.9 14.5 19V29C14.5 30.1 15.4 30.99 16.5 30.99L27.5 31C28.17 31 28.77 30.67 29.13 30.16L33.5 24L29.13 17.84ZM27.5 29H16.5V19H27.5L31.05 24L27.5 29Z"
      fill="#039855" />
  </svg>
  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} etiqueta</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie uma nova etiqueta</h6>

  <form [formGroup]="formGroup">
    <div class="flex flex-col">
      <label class="text-black mt-[15px]">Categoria</label>
      <mat-radio-group class="w-full flex justify-between" color="primary" aria-label="Selecione a Categoria"
        formControlName="category">
        <mat-radio-button value="CONTACT">Contato</mat-radio-button>
        <mat-radio-button value="PROTOCOL">Protocolo</mat-radio-button>
        <mat-radio-button value="CAMPAIGN">Campanha</mat-radio-button>
        <mat-radio-button value="INTEREST">Interesse</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="mt-[15px]">
      <label class="text-black">Palavras-chave para identificar esta etiqueta: *</label>
      <mat-chip-grid #chipGrid>
        @for (item of keywords; track item) {
        <mat-chip-row (removed)="remove(item)" [editable]="false">
          {{item}}
          <button matChipRemove [attr.aria-label]="'Remover ' + item">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        }
        <input class="input-field" placeholder="Digite a palavra-chave..." [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)" />


      </mat-chip-grid>
    </div>

    <div class="flex flex-col items-center justify-center gap-2 mt-[15px]">
      <div class="flex w-full flex-col">
        <label class="text-black">Título *</label>
        <input class="input-field" maxlength="100" formControlName="title" placeholder="Digite o título...">
        <field-error-component [field]="'title'" [label]="'Title'" [formGroup]="formGroup"></field-error-component>
      </div>
      <div class="flex w-full justify-between mt-[10px]">
        <div class="flex gap-2">
          <div class="flex flex-col">
            <label class="text-black">Cor de Fundo</label>
            <input formControlName="bgColor" class="input-field">
          </div>
          <div class="flex border-1 border-[#BDBDBD] rounded-lg w-[40px] h-[40px] mt-[22px]">
            <input class="rounded-full w-full border-none" [(colorPicker)]="colorBg" [style.background]="colorBg"
              (colorPickerSelect)="selectColor()" />
          </div>
        </div>
        <div class="flex gap-2">
          <div class="flex flex-col">
            <label class="text-black">Cor da Fonte</label>
            <input formControlName="fontColor" class="input-field">
          </div>
          <div class="flex border-1 border-[#BDBDBD] rounded-lg w-[40px] h-[40px] mt-[22px]">
            <input class="rounded-full w-full border-none" [(colorPicker)]="colorFont" [style.background]="colorFont"
              (colorPickerSelect)="selectColorFont()" />
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="flex items-center justify-center gap-2 mt-[15px]">

    </div> -->
  </form>

  <div class="flex flex-col mt-[15px]">
    <label class="text-black">Sua tag ficará assim:</label>
    <div class="mt-2">
      <span class="shadow-md px-[10px] py-[5px] font-semibold rounded-[5px]"
        [ngStyle]="{'background-color': formGroup.get('bgColor').value, 'color': formGroup.get('fontColor').value}">
        {{formGroup.get('title').value}}
      </span>
    </div>
  </div>
</div>

<div class="flex justify-between items-center w-full gap-2 mt-[15px]">
  <button
    class="h-[45px] flex flex-1 justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] flex flex-1 justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
    (click)="save()">{{isNew? 'Adicionar' : 'Alterar'}}</button>
</div>