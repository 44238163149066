<div>
  <div class="container-full flex flex-col justify-content-center">
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#D1FADF" />
      <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
      <path
        d="M14.9338 20.5H25.9338V22.5H14.9338V20.5ZM14.9338 18.5H25.9338V16.5H14.9338V18.5ZM14.9338 26.5H21.9338V24.5H14.9338V26.5ZM29.9438 23.37L30.6538 22.66C31.0438 22.27 31.6738 22.27 32.0638 22.66L32.7738 23.37C33.1638 23.76 33.1638 24.39 32.7738 24.78L32.0638 25.49L29.9438 23.37ZM29.2338 24.08L23.9338 29.38V31.5H26.0538L31.3538 26.2L29.2338 24.08Z"
        fill="#039855" />
    </svg>
    <h1 class="font-black text-[20px] text-black mt-2.5">Alterar senha</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      Preencha os dados para alterar sua senha</h6>
  </div>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-12">
        <label class="font-semibold text-black mt-2">Senha Atual</label>
        <div class="relative">
          <input [type]="hideAllPasswords ? 'password' : 'text'" formControlName="currentPassword"
            class="input-field pr-10" placeholder="Senha Atual">
          <i class="material-icons absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
            (click)="togglePasswordVisibility()">
            {{ hideAllPasswords ? 'visibility_off' : 'visibility' }}
          </i>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-3">
        <label class="font-semibold text-black">Nova Senha</label>
        <div class="relative">
          <input [type]="hideAllPasswords ? 'password' : 'text'" formControlName="newPassword" class="input-field pr-10"
            placeholder="Nova Senha">
          <i class="material-icons absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
            (click)="togglePasswordVisibility()">
            {{ hideAllPasswords ? 'visibility_off' : 'visibility' }}
          </i>
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="font-semibold text-black">Confirmar Nova Senha</label>
        <div class="relative">
          <input [type]="hideAllPasswords ? 'password' : 'text'" formControlName="confirmedPassword"
            class="input-field pr-10" placeholder="Confirmar Nova Senha">
          <i class="material-icons absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
            (click)="togglePasswordVisibility()">
            {{ hideAllPasswords ? 'visibility_off' : 'visibility' }}
          </i>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center w-full gap-2 mt-6">
      <button [matDialogClose]="" type="button"
        class="h-11 px-14 flex cursor-pointer justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]">
        Fechar
      </button>
      <button (click)="save()" type="submit" [disabled]="!formGroup.valid || loading"
        class="h-11 px-14 flex cursor-pointer justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] hover:bg-[#1340f6] focus:outline-none w-[47%]">
        <div class="flex items-center">
          <ng-container *ngIf="!loading">Alterar</ng-container>
          <mat-spinner [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
        </div>
      </button>
    </div>
  </form>
</div>