<!-- Initial page -->
<div class="content-page">
  <div class="flex justify-between items-center">
    <div>
      <h1 class="font-black text-[20px] text-black">Dashboard</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">
        Busque filtro para consultar com mais detalhes dos dados do dashboard e gráficos
      </h6>
    </div>
  </div>
  <hr />

  <!-- Header page with filters -->
  <div class="header-page">
    <section class="flex w-full flex-col filter-padding">
      <form [formGroup]="dashForm">

        <!-- Filter date -->
        <div class="flex w-full flex-col">
          <div class="flex gap-2">
            <div class="bg-white flex pl-4 items-center w-[100%] h-[70%] rounded-lg global-filter">
              <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
                <input matStartDate placeholder="Data inicial" formControlName="dateStart">
                <input matEndDate placeholder="Data final" formControlName="dateEnd">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-datepicker-actions>
                  <mat-select class="input-field rounded-lg" (selectionChange)="onPeriodChange($event)"
                    placeholder="Selecione">
                    <mat-option value="1">Hoje</mat-option>
                    <mat-option value="7">Últimos 7 dias</mat-option>
                    <mat-option value="15">Últimos 15 dias</mat-option>
                    <mat-option value="30">Últimos 30 dias</mat-option>
                    <mat-option value="60">Últimos 60 dias</mat-option>
                  </mat-select>
                  <button mat-raised-button matDatepickerApply class="btnOk">
                    OK
                  </button>
                </mat-datepicker-actions>
              </mat-date-range-picker>
            </div>

            <!-- Filter channel -->
            <div>
              <div mat-button [matMenuTriggerFor]="menuChannel"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelChannel }}</span>
              </div>

              <mat-menu #menuChannel="matMenu">
                <button mat-menu-item [value]="-1" (click)="onChannelSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of channelAttendant" [value]="item?._id"
                  (click)="onChannelSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>

            <!-- Filter department - Attendant -->
            @if (isAttendant()) {
            <div>
              <div mat-button [matMenuTriggerFor]="menuDepartment"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelDepartment }}</span>
              </div>

              <mat-menu #menuDepartment="matMenu">
                <button mat-menu-item [value]="-1" (click)="onDepartmentSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of department" [value]="item?._id"
                  (click)="onDepartmentSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>
            }

            <!-- Filter department - Supervisor -->
            @if (isSupervisor()){
            <div>
              <div mat-button [matMenuTriggerFor]="menuDepartment"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelDepartment }}</span>
              </div>

              <mat-menu #menuDepartment="matMenu">
                <button mat-menu-item [value]="-1" (click)="onDepartmentSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of department" [value]="item?._id"
                  (click)="onDepartmentSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>
            }

            <!-- Filter department - Admin -->
            @if (isAdmin()){
            <div>
              <div mat-button [matMenuTriggerFor]="menuDepartment"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelDepartment }}</span>
              </div>

              <mat-menu #menuDepartment="matMenu">
                <button mat-menu-item [value]="-1" (click)="onDepartmentSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of department" [value]="item?._id"
                  (click)="onDepartmentSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>
            }

            <!-- Filter user - Admin -->
            @if (isAdmin()) {
            <div>
              <div mat-button [matMenuTriggerFor]="menuAttendant"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelAttendant }}</span>
              </div>

              <mat-menu #menuAttendant="matMenu">
                <button mat-menu-item [value]="-1" (click)="onAttendantSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of userAttendant" [value]="item?._id"
                  (click)="onAttendantSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>
            }

            <!-- Filter user - Supervisor -->
            @if (isSupervisor()) {
            <div>
              <div mat-button [matMenuTriggerFor]="menuAttendant"
                class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm  rounded-lg global-filter">
                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill="#667085" />
                </svg>
                <span class="truncate-text">{{ labelAttendant }}</span>
              </div>

              <mat-menu #menuAttendant="matMenu">
                <button mat-menu-item [value]="-1" (click)="onAttendantSelect(-1)">Todos</button>
                <button mat-menu-item *ngFor="let item of userAttendant" [value]="item?._id"
                  (click)="onAttendantSelect(item?._id, item?.name)">
                  {{ item.name }}
                </button>
              </mat-menu>
            </div>
            }
          </div>
        </div>
      </form>
    </section>
  </div>


  <ul>
    <li *ngFor="let message of messages">{{ message | json }}</li>
  </ul>

  <div class="content-body">
    <!-- Cards with metrics -->
    <div class="w-full mt-4" *ngIf="!loadingContent">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2.5 sm:gap-3">
        <card-graphic-small-component [label]="'TME'"
          [total]="dashboard?.averageWaitingTime.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'TMA'"
          [total]="dashboard?.averageAttendanceTime.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'TTA'"
          [total]="dashboard?.attendaceTotalTime.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'Atendimentos concluídos'" (click)="openModalAttendance('CLOSED')"
          [total]="dashboard?.attendanceCloused.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'Atendimentos em andamento'" (click)="openModalAttendance('IN_PROGRESS')"
          [total]="dashboard?.attendanceProgress.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'Atendimentos pausados'" (click)="openModalAttendance('PAUSED')"
          [total]="dashboard?.attendancePaused.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'Atendimentos em aguardando'" (click)="openModalAttendance('PENDING')"
          [total]="dashboard?.attendancePending.toString()"></card-graphic-small-component>
        <card-graphic-small-component [label]="'Total de mensagens'"
          [total]="dashboard?.totalMessages.toString()"></card-graphic-small-component>
      </div>
    </div>

    <div class="flex w-full justify-between">
      <!-- Graphics of attendance by hour -->
      <div class="card-container-chart bg-white">
        <div class="mb-[15px]">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Pico de atendimento por hora</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Abaixo o gráfico com os dados de pico de atendimento por hora
          </h6>
        </div>
        <mat-card-content>
          <ngx-charts-bar-vertical [view]="" [results]="dataByHours" [scheme]="colorScheme" [gradient]="false"
            [xAxis]="true" [yAxis]="true" xAxisLabel="Hora" yAxisLabel="Valor">
          </ngx-charts-bar-vertical>
        </mat-card-content>
      </div>

      <!-- Graphics of attendance by day -->
      <div class="card-container-chart bg-white">
        <div class="mb-[15px]">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Pico de atendimento por dia</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Abaixo o gráfico com os dados de pico de atendimento por dia
          </h6>
        </div>
        <mat-card-content>
          <ngx-charts-bar-vertical [view]="" [results]="peakAttendance" [scheme]="colorScheme" [gradient]="false"
            [xAxis]="true" [yAxis]="true" [legend]="legendLC" xAxisLabel="Hora" yAxisLabel="Valor">
          </ngx-charts-bar-vertical>
        </mat-card-content>
      </div>
    </div>

    <div class="flex w-full justify-between">
      <!-- Graphics with new contacts by day -->
      <div class="card-container-chart bg-white">
        <div class="mb-[15px]">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Novos contatos por dia</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Abaixo o gráfico com os dados de novos contatos por dia
          </h6>
        </div>
        <mat-card-content>
          <ngx-charts-bar-vertical [view]="" [results]="newContactWeekend" [scheme]="colorScheme" [gradient]="false"
            [xAxis]="true" [yAxis]="true" [legend]="legendLC" xAxisLabel="Hora" yAxisLabel="Valor">
          </ngx-charts-bar-vertical>
        </mat-card-content>
      </div>

      <!-- Graphics with new contacts by channel -->
      <div class="card-container-chart bg-white">
        <div class="mb-[15px]">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Novos contatos por canal</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Abaixo o gráfico com os dados de novos contatos por canal
          </h6>
        </div>
        <mat-card-content>
          <ngx-charts-advanced-pie-chart [view]="" [scheme]="colorScheme" [results]="newContact" [gradient]="true"
            [percentageFormatting]="formatPercentage">
          </ngx-charts-advanced-pie-chart>
        </mat-card-content>
      </div>
    </div>

    <!-- Metrics of campaign -->
    <div class="card-container-online" *ngIf="campaign.length > 0">
      <div class="flex mb-[15px]" *ngFor="let campaign of campaign">
        <div class="birth bg-white">
          <div class="flex items-center">
            <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">{{ campaign.channelName }}</h1>
          </div>
          <div class="content-card">
            <div class="metric">
              <span>Campanhas</span>
              <span class="value">{{ campaign?.totalRecord }}</span>
            </div>
            <div class="metric">
              <span>Mensagens</span>
              <span class="value">{{ campaign?.totalMessages }}</span>
            </div>
            <div class="metric">
              <span>Custo</span>
              <span class="value">US$ {{ campaign?.coust.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card dont campaign -->
    <div class="card-container-campaign bg-white" *ngIf="campaign.length === 0">
      <div class="flex gap-2 items-center p-3">
        <svg width="44" height="45" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 11.0957C18 11.7657 18 12.4257 18 13.0957C19.2 13.0957 20.76 13.0957 22 13.0957C22 12.4257 22 11.7657 22 11.0957C20.76 11.0957 19.2 11.0957 18 11.0957Z"
            fill="#667085" />
          <path
            d="M16 17.7057C16.96 18.4157 18.21 19.3557 19.2 20.0957C19.6 19.5657 20 19.0257 20.4 18.4957C19.41 17.7557 18.16 16.8157 17.2 16.0957C16.8 16.6357 16.4 17.1757 16 17.7057Z"
            fill="#667085" />
          <path
            d="M20.4 5.6957C20 5.1657 19.6 4.6257 19.2 4.0957C18.21 4.8357 16.96 5.7757 16 6.4957C16.4 7.0257 16.8 7.5657 17.2 8.0957C18.16 7.3757 19.41 6.4457 20.4 5.6957Z"
            fill="#667085" />
          <path
            d="M4 9.0957C2.9 9.0957 2 9.9957 2 11.0957V13.0957C2 14.1957 2.9 15.0957 4 15.0957H5V19.0957H7V15.0957H8L13 18.0957V6.0957L8 9.0957H4ZM9.03 10.8057L11 9.6257V14.5657L9.03 13.3857L8.55 13.0957H4V11.0957H8.55L9.03 10.8057Z"
            fill="#667085" />
          <path d="M15.5 12.0957C15.5 10.7657 14.92 9.5657 14 8.7457V15.4357C14.92 14.6257 15.5 13.4257 15.5 12.0957Z"
            fill="#667085" />
        </svg>
        <div class="flex flex-col ml-2">
          <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Nenhuma campanha encontrada</h1>
          <h6 class="text-zinc-400 font-light text-sm">
            Altere no filtro para buscar as campanhas
          </h6>
        </div>
      </div>
    </div>

    <!-- Performance attendant table -->
    <div class="card-container-online">
      <div class="w-full flex items-center justify-center">
        <loading-component *ngIf="loadingSpinner$ | async"></loading-component>
      </div>
      <div class="birth bg-white" *ngIf="!(loadingSpinner$ | async) && attendanceOn?.total > 0">
        <mat-card-header class="flex items-center gap-2">
          <mat-icon class="text-[#667084]">person</mat-icon>
          <div class="flex flex-col ml-2">
            <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Performance de atendente</h1>
            <h6 class="text-zinc-400 font-light text-sm">
              Abaixo está os dados da performance dos atendentes
            </h6>
          </div>
        </mat-card-header>
        <mat-card-content class="content-birth">
          <div class="table-container">
            <table class="min-w-full divide-gray-100 divide-y-[2px]">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Total Chats
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Aguardando</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Abertos</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Pausados</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Fechados</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Total mensagens</th>
                </tr>
              </thead>
              <tbody class="divide-gray-100 divide-y-[2px] bg-white">
                <tr class="rounded-none" *ngFor="let item of attendanceOn?.list">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{ item?.user?.length > 20 ? item?.user?.substring(0, 15) + '...' : item?.user }}</p>
                  </td>

                  <td class="flex-wrap px-3 py-4 text-sm font-light text-gray-900">
                    <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
                      [ngStyle]="{'background-color': '#4214f6'}">
                      {{item.totalChat}}
                    </span>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.totalPending}}</p>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.totalInProgress}}</p>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.totalPaused}}</p>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.totalClosed}}</p>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.totalMessages}}</p>
                  </td>
                </tr>

                <tr class=""></tr>
              </tbody>
            </table>
          </div>
          <paginator *ngIf="attendanceOn.total > attendanceOn.perPage" class="flex justify-content-center mt-3 mb-3"
            [pager]="attendanceOn" (onPager)="loadPageOnline($event)">
          </paginator>
        </mat-card-content>
      </div>

      <!-- Performance dont attendant -->
      <div class="bg-white rounded-lg"
        *ngIf="(attendanceOn.total == 0 || attendanceOn.list.length == 0) && !(loadingSpinner$ | async)">
        <div class="card-container-campaign">
          <div class="flex p-3 items-center">
            <mat-icon class="text-[#667084]">person</mat-icon>
            <div class="flex flex-col ml-2">
              <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Nenhuma performe de atendente</h1>
              <h6 class="text-zinc-400 font-light text-sm">
                Altere no filtro para buscar a performe do atendente
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Birthday table -->
    <div class="card-container-birth pb-[15px]">
      <div *ngIf="pager.total > 0">
        <div class="birth bg-white">
          <mat-card-header class="flex items-center gap-2">
            <mat-icon class="text-[#667084]">cake</mat-icon>
            <div class="flex flex-col ml-2">
              <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Aniversariante do mês</h1>
              <h6 class="text-zinc-400 font-light text-sm">
                Abaixo está os aniversariantes do mês
              </h6>
            </div>
          </mat-card-header>
          <mat-card-content class="content-birth">
            <table class="min-w-full divide-gray-100 divide-y-[3px]">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome/Email</th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Data</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Idade</th>
                </tr>
              </thead>
              <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                <tr class="rounded-none" *ngFor="let item of pager.list">
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="flex gap-2">
                      <div>
                        <img class="w-10 h-10 rounded-full" [src]="getUserImage(item)" (error)="onImageError($event)" />
                      </div>

                      <div class="flex flex-col">
                        <div class="font-light">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p>{{item.birthDate | date: 'dd/MM/yyyy'}}</p>
                  </td>
                  <td class="px-3 py-4 text-sm">
                    <p>{{transform(item.birthDate)}} anos</p>
                  </td>
                </tr>

                <tr class=""></tr>
              </tbody>
            </table>
          </mat-card-content>
        </div>

        <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
          (onPager)="loadPage($event)">
        </paginator>
      </div>

      <!-- Birthday dont -->
      <div class="bg-white rounded-lg" *ngIf="pager.total == 0">
        <div class="card-container-campaign">
          <div class="flex p-3 items-center">
            <mat-icon class="text-[#667084]">cake</mat-icon>
            <div class="flex flex-col ml-2">
              <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Nenhum aniversariante do mês</h1>
              <h6 class="text-zinc-400 font-light text-sm">
                Nenhum contato ou usuário fazendo aniversário no atual mês
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>