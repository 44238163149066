import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'lib-trend-core';
import { UpdatePasswordComponent} from './update-password.component';

@NgModule({
  imports: [
    CommonModule,           
    SharedModule,
    
  ],
  declarations: [
    UpdatePasswordComponent         
  ],
  exports: [
    UpdatePasswordComponent         
  ],
})
export class UpdatePasswordModule { }
