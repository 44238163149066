import { CommonModule, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireStorageModule } from '@angular/fire/compat/storage'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { AlertModule, AuthGuard, AuthGuardAdmin, AuthGuardAttendant, AuthGuardSupervisor, CONSTANTS, ENV, HttpErrorInterceptor, JwtService, SharedModule, customHttpProvider } from 'lib-trend-core'
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask'
import { GlobalConfig, ToastrModule } from 'ngx-toastr'
import { Constants } from '../environments/constantes'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AttendanceModule } from './modules/attendance/attendance.module'
import { CampaignModule } from './modules/campaign/campaign.module'
import { ChannelModule } from './modules/channel/channel.module'
import { ChatbotModule } from './modules/chatbot/chatbot.module'
import { ContactGroupModule } from './modules/contact-group/contact-group.module'
import { ContactModule } from './modules/contact/contact.module'
import { CustomFieldModule } from './modules/custom-field/custom-field.module'
import { DashboardModule } from './modules/dashboard/dashboard.module'
import { DepartmentModule } from './modules/department/department.module'
import { FlowModule } from './modules/flow/flow.module'
import { IntegrationsModule } from './modules/integrations/integrations.module'
import { LayoutModule } from './modules/layout/layout.module'
import { NotificationModule } from './modules/notification/notification.module'
import { RedirectModule } from './modules/redirect/redirect.module'
import { ReportModule } from './modules/report/report.module'
import { ResetPasswordModule } from './modules/reset-password/reset-password.module'
import { ResponseModule } from './modules/response/response.module'
import { TagModule } from './modules/tag/tag.module'
import { UpdatePasswordModule } from './modules/update-password/update-password.module'
import { UserModule } from './modules/user/user.module'
import { WhatsappSignupModule } from './modules/whatsapp-signup/whatsapp-signup.module'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const toastGlobalConfig: Partial<GlobalConfig> = {
  preventDuplicates: true,
  maxOpened: 3
};

registerLocaleData(localePt)

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RedirectModule,
    NgxChartsModule,
    LayoutModule,
    SharedModule,
    DepartmentModule,
    DashboardModule,
    ReportModule,
    IntegrationsModule,
    TagModule,
    UserModule,
    ResponseModule,
    ChannelModule,
    AlertModule,
    FlowModule,
    ContactGroupModule,
    ContactModule,
    CampaignModule,
    AngularFireStorageModule,
    ChatbotModule,
    UpdatePasswordModule,
    ResetPasswordModule,
    NotificationModule,
    AttendanceModule,
    WhatsappSignupModule,
    ToastrModule.forRoot(toastGlobalConfig),
    SweetAlert2Module.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    EffectsModule.forRoot(),
    StoreModule.forRoot(),
    CustomFieldModule,
    (
      !environment.production
        ? StoreDevtoolsModule.instrument({
          name: 'Attendance DevTools',
          maxAge: 25, // Retains last 25 states
          logOnly: environment.production,
          connectInZone: true,
        })
        : []
    ),
  ],
  providers: [
    AuthGuard,
    AuthGuardAdmin,
    AuthGuardAttendant,
    AuthGuardSupervisor,
    provideEnvironmentNgxMask(maskConfig),
    customHttpProvider,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ENV, useValue: environment },
    { provide: CONSTANTS, useValue: Constants },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
