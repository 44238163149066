<mat-toolbar class="bg-white mb-4">
  <div class="flex gap-2">
    <img class="w-8 h-8 rounded-lg mr-2" [src]="getCompanyImage(getCompanyCurrentUser())" />
    <span>{{getNameCompanyCurrentUser()}}</span>
  </div>
  <span class="example-spacer"></span>
  <button class="flex items-center justify-center h-[48px] w-[48px] rounded-full bg-secondary-lilas mr-2" mat-icon-button (click)="toggleAudio()">
    <span *ngIf="!isAudioOn">
      <mat-icon class="text-black">volume_off</mat-icon>
    </span>
    <span *ngIf="isAudioOn">
      <mat-icon class="text-black">volume_up</mat-icon>
    </span>
  </button>
  <button mat-icon-button class="flex items-center justify-center h-[48px] w-[48px] rounded-full bg-secondary-lilas"
    [mat-menu-trigger-for]="menuNotification">
    <mat-icon class="text-black">notifications</mat-icon>
  </button>
  <mat-menu #menuNotification [class]="'menu-notification'">
    <h1 class="font-black text-[20px] text-black p-3">Notificações</h1>
    <div *ngIf="!loadingContent && pager.total == 0" class="p-4">
      <empty-records-component [message]="'Nenhuma notificação no momento'"
        [subMessage]="'Quando houver notificações, elas aparecerão aqui'">
      </empty-records-component>
    </div>
    @for (notification of pager.list; track $index) {
    <button mat-menu-item class="p-3" id="button-menu-notification" routerLink="/notification">
      <span class="flex flex-col">
        <p class="flex items-center justify-between">
          {{ notification.description | truncate: 55 }}
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" rx="6" fill="#4213F6" />
          </svg>
        </p>
        <small class="text-[#4213F6] font-light text-sm my-2 flex items-center gap-1">
          {{ notification?.createdAt | date: 'dd/MM/yyy' }}
          <mat-icon class="text-sm mt-1">notifications_active</mat-icon>
        </small>
      </span>
    </button>
    }
    <a routerLink="/notification"
      class="h-[35px] p-3 flex justify-center items-center hover:underline font-normal w-[100%] mt-[15px]">
      Ver mais</a>
  </mat-menu>

  <div class="profile" [matMenuTriggerFor]="appMenu">
    <button mat-icon-button
      class="flex items-center justify-center h-[48px] w-[48px] rounded-full bg-secondary-lilas ml-2"
      [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: '{{getNameCurrentUser()}}'}">
      <mat-icon class="text-black">person</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
      <ng-template matMenuContent let-name="name">
        <button mat-menu-item>
          {{getNameCurrentUser()}}<small class="ml-2 text-gray-400">{{getEmailCurrentUser()}}</small>
        </button>
        <button mat-menu-item (click)="upadatePassword()">Atualizar Senha</button>
        <button mat-menu-item [routerLink]="['/config']">Configurações</button>
        <button mat-menu-item (click)="logout()">Sair</button>
      </ng-template>
    </mat-menu>
  </div>

</mat-toolbar>