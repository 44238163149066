import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, AuthenticationService, UtilHelper } from 'lib-trend-core';

@Component({
  selector: 'redirect-component',
  templateUrl: 'redirect.component.html',
})
export class RedirectComponent extends AbstractComponent implements OnInit {

  public returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const token = super.getQueryParam('token');
    const json = UtilHelper.getGeneratedTokenTowardsApps(token);
    const idUser = json.idUser;
    const idCompany = json.idCompany;

    this.authenticationService.loginImpersonate(idUser, idCompany).subscribe({
      next: (payload: any) => {
        this.router.navigate(['/'])
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

}
