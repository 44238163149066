<div class="flex flex-1 flex-col">
  <div class="w-full flex items-center">
    <form [formGroup]="formGroup" class="flex w-full flex-col mb-4">
      <div class="flex w-full flex-col">
        <div class="flex flex-1 w-100 justify-end mb-4"></div>
        <div class="flex gap-2">
          <div class="flex flex-1">
            <label for="simple-search" class="sr-only">Pesquisar</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                <!-- Ícone de pesquisa -->
              </div>
              <input type="search" #searchBox (keyup)="search(searchBox.value)" class="input-field pl-10"
                placeholder="Pesquisar..." style="padding-left: 30px; box-sizing: border-box;" />
            </div>
          </div>
          <div class="flex">
            <button
              class="ml-4 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
              (click)="add(); $event.stopPropagation()">+ Adicionar Grupo</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]" *ngIf="pager.total > 0">
    <div class="w-100 px-0">
      <div class="flex justify-start gap-4 flex-wrap">
        <div
          class="bg-white p-3 shadow rounded-lg custom-max:w-[18%] cursor-pointer custom-min:w-[30%] md:w-[calc(50%-16px)] sm:w-[100%] h-[180px] mobile:w-[100%] border-2 border-gray-100"
          *ngFor="let item of pager.list" (click)="listContactGroup(item._id); $event.stopPropagation()">
          <div class="flex w-100 justify-between items-center pb-2 border-b-2 border-gray-100">
            <div class="flex gap-3 flex-1">
              <img class="w-8 h-8 rounded-full" src="../../../assets/images/logo-icon-sidebar.png" alt="{{item?.name}}">
              <span class="text-sm text-ellipsis overflow-hidden">{{item?.name}}</span>
            </div>
            <div class="inline-block text-left" x-data="{ menu: false }">
              <button x-on:click="menu = ! menu; $event.stopPropagation()" type="button"
                class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none relative" id="menu-button"
                aria-expanded="true" aria-haspopup="true">
                <span class="sr-only"></span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
              <div x-show="menu" x-on:click.away="menu = false"
                class="absolute w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="" role="none">
                  <a href="javascript:void(0)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                    role="menuitem" tabindex="-1" id="menu-item-0" (click)="edit(item); $event.stopPropagation()">
                    Editar
                  </a>
                </div>
                <div class="" role="none">
                  <a href="javascript:void(0)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                    role="menuitem" tabindex="-1" id="menu-item-1" (click)="delete(item); $event.stopPropagation()" *ngIf="isAdmin()">
                    Remover
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col h-full justify-start items-start mt-2 gap-2">
            <div class="flex flex-col">
              <span class="text-base mt-2 text-gray-400">{{ getCountContacts(item._id) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <empty-records-component *ngIf="pager.total == 0" [message]="'Você ainda não tem grupo de contatos adicionados'"
    [subMessage]="'Adicione seu primeiro'" [labelButton]="'+ Adicionar'" (emitEvent)="add()">
  </empty-records-component>
</div>
