import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { CustomFieldEditComponent } from './custom-field-edit/custom-field-edit.component';
import { CustomFieldComponent } from './custom-field.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CustomFieldComponent,
    CustomFieldEditComponent,
  ],
  exports: [
    CustomFieldComponent,
    CustomFieldEditComponent,
  ]
})
export class CustomFieldModule { }
