import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Pager, Tag, TagService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { TagEditComponent } from './tag-edit/tag-edit.component';

@Component({
  selector: 'tag-component',
  templateUrl: 'tag.component.html'
})
export class TagComponent extends AbstractComponent implements OnInit {

  pager: Pager<Tag> = new Pager<Tag>({ perPage: 10 });

  listObservable: Observable<Pager<Tag>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  labelFilter: string = 'Categorias';
  defaultValue: string = '';

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public tagService: TagService,
  ) {
    super(injector);
    this.createForm();
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.formGroup.controls['category'].valueChanges.subscribe(value => {
      this.getList();
    });
    this.getList();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      category: [null],
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.tagService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Tag>) => {
      this.pager = pager;
    });
  }

  setValueSearch(term: string, label: string = 'Categorias'): void {
    this.defaultValue = term;
    this.formGroup.get('category')?.setValue(term);
    this.labelFilter = label;
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  getList() {
    this.loadingContent = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    const category = this.formGroup.get('category').value;
    if (category) {
      this.searchParams['category'] = category;
    }

    this.tagService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value
        this.setupObservableSearch();
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(TagEditComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: Tag): void {
    const dialogRef = this.dialog.open(TagEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  delete(item: Tag): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.tagService.delete(item._id).subscribe({
          next: (value) => {
            this.alertService.success('Tag excluída com sucesso.');
            this.getList();
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    })
  }


}
