import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NavigationCancel, NavigationEnd } from '@angular/router'
import {
  AbstractComponent,
  AuthenticationService,
  Notification,
  NotificationService,
  Pager,
  SocketService,
} from 'lib-trend-core'
import { UpdatePasswordComponent } from '../update-password/update-password.component'

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends AbstractComponent implements OnInit {
  pager: Pager<Notification> = new Pager<Notification>()

  breadcrumbs: string = 'Dashboard'

  constructor(
    injector: Injector,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private socketService: SocketService,
    public dialog: MatDialog
  ) {
    super(injector)
    this.routingChanges()
  }

  ngOnInit(): void {
    this.configSocket()
    this.getNotifications()
  }

  logout() {
    this.authenticationService.logout()
    window.location.href = '/login'
  }

  routingChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        let url = event['url'].split('/')[1]
        switch (url) {
          case 'home':
            this.breadcrumbs = 'Dashboard'
            break
          case 'category':
            this.breadcrumbs = 'Categorias'
            break
          case 'contact':
            this.breadcrumbs = 'Contatos'
            break
          case 'contact-group':
            this.breadcrumbs = 'Grupo de Contatos'
            break
          case 'department':
            this.breadcrumbs = 'Departamentos'
            break
          case 'attendance':
            this.breadcrumbs = 'Atendimento'
            break
          case 'tag':
            this.breadcrumbs = 'Etiquetas'
            break
          case 'user':
            this.breadcrumbs = 'Usuários'
            break
          case 'response':
            this.breadcrumbs = 'Respostas'
            break
          case 'channel':
            this.breadcrumbs = 'Canais'
            break
          case 'flow':
            this.breadcrumbs = 'Flow'
            break
          case 'campaign':
            this.breadcrumbs = 'Campanhas'
            break
          case 'notification':
            this.breadcrumbs = 'Notificações'
            break
          case 'config':
            this.breadcrumbs = 'Configurações do Sistema'
            break
          case 'integrations':
            this.breadcrumbs = 'Integrações'
            break
          default:
            this.breadcrumbs = 'Dashboard'
            break
        }
      }
    })
  }

  upadatePassword() {
    this.dialog.open(UpdatePasswordComponent, {
      width: '500px',
      height: '480px',
    })
  }

  configSocket() {
    const code = super.getCodeCompanyCurrentUser();
    const snameEventNotification = `event_notification_${code}`;
    this.socketService.listen(snameEventNotification).subscribe(
      (notification: Notification) => {
        let title = notification.title;
        let message = notification.description;
        message += `<br><br><a href="/attendance/panel?id=${notification.attendance._id}&status=${notification.attendance.status}" target="_blank">Ver atendimento</a>`;
        this.alertService.info(title, message);
      }
    );
  }

  private getNotifications(): void {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    }

    this.notificationService.getAll(1, 7, '', this.searchParams).subscribe({
      next: (pager: Pager<Notification>) => {
        this.pager = pager
      },
      error: (err) => this.alertService.error(err.error.message),
    })
  }
}
