import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractComponent, AuthenticationService, Company, SocketService, UserService } from 'lib-trend-core';
import { interval, map, Subject, takeUntil } from 'rxjs';
import { Constants } from '../../../../environments/constantes';

@Component({
  selector: 'base-root',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent extends AbstractComponent implements OnInit, OnDestroy {

  private stopTimer$ = new Subject<void>();

  constructor(
    injector: Injector,
    private socketService: SocketService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) {
    super(injector);
    this.socketService.connect();
    this.configSocket();
    this.startTokenRefresh();
  }

  ngOnInit(): void {
    this.updateUserSession();
    this.fetchMaxUsersAndTotalUsersCompanyUserLogged();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.socketService.disconnect();
  }

  startTokenRefresh() {
    interval(5 * 60 * 1000) // 5 minutes
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.updateUserSession())
      )
      .subscribe({
        next: () => console.log('The user session was updated!'),
        error: (err) => console.error('Error updating user session', err)
      });
  }

  private fetchMaxUsersAndTotalUsersCompanyUserLogged(): void {
    const company: Company = this.getCompanyCurrentUser();

    this.userService.getCountTotalUsers(company._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          localStorage.setItem(Constants.currentMaxUsersCompany, company?.maxUsers.toString());
          localStorage.setItem(Constants.currentTotalUsers, value.toString());
        },
        error: () => { }
      });
  }

  updateUserSession() {
    this.authenticationService.refreshJwtTokenUserLogged()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  configSocket() {
    const code = super.getCodeCompanyCurrentUser();
    const idCurrentUser = super.getIDCurrentUser();
    const snameEventUser = `event_user_${code}_${idCurrentUser}`;
    this.socketService.listen(snameEventUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.updateUserSession();
          this.fetchMaxUsersAndTotalUsersCompanyUserLogged();
        }
      );
  }

  toggle() {
  }

  isExpanded = true;
  menuItems = [
    { icon: 'home', text: 'Home' },
    { icon: 'account_circle', text: 'Profile' },
    { icon: 'settings', text: 'Settings' },
  ];

  toggleSidenav() {
    this.isExpanded = !this.isExpanded;
  }

}