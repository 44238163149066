import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, CustomField, CustomFieldService, TypeFieldEnum } from 'lib-trend-core';

@Component({
  selector: 'custom-field-edit-component',
  templateUrl: 'custom-field-edit.component.html',
  styleUrls: ['custom-field-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomFieldEditComponent extends AbstractComponent {
  fieldsCustom!: CustomField;
  listTypeField: Array<TypeFieldEnum> = new Array<TypeFieldEnum>(TypeFieldEnum.TEXT, TypeFieldEnum.NUMBER, TypeFieldEnum.FREETEXT, TypeFieldEnum.DATE);
  previewData: { name: string; type: string, description?: string } = { name: 'Exemplo', type: 'TEXT', description: '' };

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CustomFieldEditComponent>,
    private fieldsCustomService: CustomFieldService,
    @Inject(MAT_DIALOG_DATA) public data: CustomField,
  ) {
    super(injector);
    this.fieldsCustom = {} as CustomField;
    this.createForm();
  }

  ngOnInit() {
    this.isNew = (!!this.data ? false : true);

    if (!this.isNew) {
      this.fieldsCustom = { ...this.data };
      this.formGroup.patchValue(this.data);
      this.updateValue(this.data);
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      type: [TypeFieldEnum.TEXT],
      description: [null]
    });

    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.fieldsCustom, value);
      this.updateValue(value);
    });
  }

  private updateValue(value: any): void {
    this.previewData = {
      name: value.name,
      type: value.typeField,
      description: value.description || '',
    };
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (this.isNew) {
      this.fieldsCustomService.create(this.fieldsCustom).subscribe({
        next: (value) => {
          this.loading = false
          this.dialogRef.close(value);
          this.alertService.success('Campo salvo com sucesso.');
        },
      });
    } else {
      this.fieldsCustomService.update(this.fieldsCustom._id, this.fieldsCustom).subscribe({
        next: (value) => {
          this.loading = false
          this.dialogRef.close(value);
          this.alertService.success('Campo atualizado com sucesso.');
        },
      });
    }
  }
}
