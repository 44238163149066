<ng-container class="absolute flex inset-0 bg-[#eff1f5] h-screen" autosize>
  <mat-sidenav-container fullscreen autosize>
    <mat-sidenav #sidenav mode="side" position="start" opened="true">
      <sidebar-component (toggleSidebarEmit)="toggle()"></sidebar-component>
    </mat-sidenav>
    <mat-sidenav-content>
      <header-component></header-component>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>