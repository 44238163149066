import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContactGroupComponent } from './contact-group.component';
import { ContactGroupListComponent } from './contact-group-list/contact-group-list.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ContactGroupComponent,
    ContactGroupListComponent,
  ],
  exports: [
    ContactGroupComponent
  ]
})
export class ContactGroupModule { }
