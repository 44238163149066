<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} campo</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo campo personalizado</h6>

  <form [formGroup]="formGroup" style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
    <div class="flex w-full flex-col">
      <div class="flex gap-2">
        <div class="flex flex-col flex-1">
          <label class="font-medium text-black">Nome *</label>
          <input class="input-field" maxlength="100" formControlName="name" placeholder="Digite o nome..." required>
          <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
          </field-error-component>
        </div>
        <div class="flex flex-row flex-1 w-full gap-2">
          <div class="flex flex-col w-full">
            <label class="font-medium text-black">Tipo do campo *</label>
            <mat-select class="input-field" formControlName="type">
              @for (item of listTypeField; track $index) {
              <mat-option [value]="item">{{ item === 'TEXT' ? 'Texto' : item === 'NUMBER' ? 'Número' : item ===
                'FREETEXT' ? 'Texto livre' : item === 'DATE' ? 'Data' : '-'
                }}</mat-option>
              }
            </mat-select>
            <field-error-component [field]="'type'" [label]="'Tipo do campo'" [formGroup]="formGroup">
            </field-error-component>
          </div>
        </div>
      </div>
      <div class="flex gap-2 mt-[15px]">
        <div class="flex flex-col flex-1">
          <label class="font-medium text-black">Descrição</label>
          <input type="text" class="input-field" formControlName="description" placeholder="Digite uma descrição...">
          <small class="text-zinc-400 font-light text-sm my-2">Por exemplo: "Esse campo personalizado..."</small>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-2">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">
    {{ !loading ? (isNew? 'Adicionar' : 'Alterar') : '' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>