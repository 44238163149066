import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractComponent, AuthenticationService, SocketService, User } from 'lib-trend-core';

@Component({
  selector: 'base-root',
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(
    injector: Injector,
    private socketService: SocketService,
    private authenticationService: AuthenticationService,
  ) {
    super(injector);
    this.socketService.connect();
    this.configSocket();
  }

  ngOnInit(): void {
    this.updateUserSession();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.socketService.disconnect();
  }

  updateUserSession() {
    this.authenticationService.refreshJwtTokenUserLogged().subscribe();
  }

  configSocket() {
    const code = super.getCodeCompanyCurrentUser();
    const idCurrentUser = super.getIDCurrentUser();
    const snameEventUser = `event_user_${code}_${idCurrentUser}`;
    this.socketService.listen(snameEventUser).subscribe(
      (user: User) => {
        this.updateUserSession();
      }
    );
  }

  toggle() {
  }

  isExpanded = true;
  menuItems = [
    { icon: 'home', text: 'Home' },
    { icon: 'account_circle', text: 'Profile' },
    { icon: 'settings', text: 'Settings' },
  ];

  toggleSidenav() {
    this.isExpanded = !this.isExpanded;
  }

}