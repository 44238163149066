import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContactGroupModule } from '../contact-group/contact-group.module';
import { ContactComponent } from './contact.component';

@NgModule({
  imports: [
    SharedModule,
    ContactGroupModule
  ],
  declarations: [
    ContactComponent
  ],
  exports: [
    ContactComponent
  ]
})
export class ContactModule { }
