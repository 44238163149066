import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ContactGroup, ContactGroupService, ConfirmationComponent, Pager, ContactGroupEditComponent } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, mergeMap, switchMap } from 'rxjs';
import { ContactGroupListComponent } from './contact-group-list/contact-group-list.component';

@Component({
  selector: 'contact-group-component',
  templateUrl: 'contact-group.component.html'
})
export class ContactGroupComponent extends AbstractComponent implements OnInit {

  pager: Pager<ContactGroup> = new Pager<ContactGroup>({ perPage: 9 });
  listObservable: Observable<Pager<ContactGroup>>;
  private termoDaBusca: Subject<string> = new Subject<string>();
  private totalContacts!: Array<any>;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public contactGroupService: ContactGroupService,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit(): void {
    this.getList();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null],
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.contactGroupService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<ContactGroup>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }


  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    this.contactGroupService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).pipe(
      mergeMap((pager: Pager<ContactGroup>) => {
        this.pager = pager;
        const idsContacts = this.pager.list.map(group => group._id);
        return this.contactGroupService.getCountContactsByGroup(idsContacts);
      }),
    ).subscribe((value: any) => {
      this.totalContacts = value;
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(ContactGroupEditComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: ContactGroup): void {
    const dialogRef = this.dialog.open(ContactGroupEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  delete(item: ContactGroup): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.contactGroupService.delete(item._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Grupo de Contato excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  getCountContacts(groupId: string): string {
    if (this.totalContacts) {
      const total = this.totalContacts
        .filter((value) => value && value._id === groupId)
        .map((value) => value.totalContacts)
        .toString();

      return total.length === 0 ? 'Nenhum Participante' : `${total} Participantes`;
    }

    return 'N/A';
  }
  listContactGroup(groupId: string): void {
    this.dialog.open(ContactGroupListComponent, {
      width: '800px',
      data: { groupId }
    });
  }
}
