import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, SidebarService, UtilHelper } from 'lib-trend-core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sidebar-component',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent extends AbstractComponent implements OnInit {

  @Output() toggleSidebarEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  isExpanded = true;

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private sidebarService: SidebarService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.sidebarService.isExpanded$.subscribe(state => {
      this.isExpanded = state;
    });
  }

  goToWebAdmin() {
    const idUser = super.getIDCurrentUser();
    const idCompany = super.getIDCurrentCompany();
    const base64 = UtilHelper.generateTokenTowardsApps(idUser, idCompany);
    window.open(`${environment.trendWebAdminUrl}/redirect?token=${base64}`, '_blank');
  }

  toggle() {
  }

  menuItems = [
    { icon: 'home', text: 'Home' },
    { icon: 'account_circle', text: 'Profile' },
    { icon: 'settings', text: 'Settings' },
  ];

  toggleSidenav() {
    this.sidebarService.toggleExpandedState();
  }

  public get env() {
    return environment.env;
  }

}
