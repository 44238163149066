import { Component, Injector, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactGroupService } from 'lib-trend-core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'contact-group-component',
  templateUrl: 'contact-group-list.component.html'
})
export class ContactGroupListComponent implements OnInit {

  contactsByGroup: Array<any> = []; 
  filteredContacts: Array<any> = [];
  selectedGroupId: string | null;
  searchControl: FormControl = new FormControl('');
  public loadingContent: boolean = false;
  constructor(
    injector: Injector,
    public contactGroupService: ContactGroupService,
    @Inject(MAT_DIALOG_DATA) public data: { groupId: string } 
  ) {
    this.selectedGroupId = data.groupId;
  }

  ngOnInit(): void {
    if (this.selectedGroupId) {
      this.getContactsByGroup(this.selectedGroupId);
    } else {
      console.error('Group ID não está definido'); 
    }


    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {
        this.filterContacts(searchTerm);
      });
  }

  getContactsByGroup(groupId: string): void {
    this.contactGroupService.getContactsByGroup(groupId).subscribe({
      next: (contacts: any) => {
        this.contactsByGroup = contacts; 
        this.filteredContacts = contacts;
      }
    });
  }

  filterContacts(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredContacts = this.contactsByGroup;
    } else {
      this.filteredContacts = this.contactsByGroup.filter(contact =>
        contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.phone.includes(searchTerm)
      );
    }
  }
}
