import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, AlertService, RoleUserEnum, Tag, TagService, TagTypeEnum } from 'lib-trend-core';

@Component({
  selector: 'tag-edit-component',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.scss']
})
export class TagEditComponent extends AbstractComponent {

  tag: Tag;
  colorBg: string = '#A5A6F6';
  colorFont: string = '#000';

  keywords: string[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<TagEditComponent>,
    public tagService: TagService,
    @Inject(MAT_DIALOG_DATA) public data: Tag
  ) {
    super(injector);
    this.tag = {} as Tag;
    this.createForm();
  }


  ngOnInit() {
    this.isNew = (!!this.data ? false : true);
    if (!this.isNew) {
      this.tag = this.data;
      this.formGroup.patchValue(this.tag);
      this.keywords = this.tag.keyword;
      this.colorBg = this.tag.bgColor;
      this.colorFont = this.tag.fontColor;
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      bgColor: [{ value: this.colorBg, disabled: true }, Validators.required],
      fontColor: [{ value: this.colorFont, disabled: true }, Validators.required],
      category: [TagTypeEnum.CONTACT, Validators.required],
      title: [null, Validators.required],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.tag, value);
    });
  }


  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return;
    }
    this.tag.bgColor = this.formGroup.get('bgColor').value;
    this.tag.keyword = this.keywords;
    this.tag.fontColor = this.formGroup.get('fontColor').value;

    if(this.isNew) {
      this.tagService.create(this.tag).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Tag salva com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    } else {
      this.tagService.update(this.tag._id, this.tag).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Tag salva com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    }
  }

  

  selectColor() {
    this.formGroup.get('bgColor').setValue(this.colorBg);
  }

  selectColorFont() {
    this.formGroup.get('fontColor').setValue(this.colorFont);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.keywords.push(value);
    }
    event.chipInput!.clear();
  }

  remove(value: string): void {
    const index = this.keywords.indexOf(value);

    if (index >= 0) {
      this.keywords.splice(index, 1);

    }
  }
}
