import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, AlertModalCheckComponent, CustomField, CustomFieldService, Pager, TypeFieldEnum } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs';
import { CustomFieldEditComponent } from './custom-field-edit/custom-field-edit.component';

@Component({
  selector: 'custom-field-component',
  templateUrl: 'custom-field.component.html',
})
export class CustomFieldComponent extends AbstractComponent implements OnInit {
  private selectedStatus: TypeFieldEnum | null = null;

  pager: Pager<CustomField> = new Pager<CustomField>({ perPage: 10 });
  listObservable: Observable<Pager<CustomField>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  listTypeField: Array<TypeFieldEnum> = new Array<TypeFieldEnum>(TypeFieldEnum.TEXT, TypeFieldEnum.NUMBER, TypeFieldEnum.FREETEXT, TypeFieldEnum.DATE);
  labelType: string = 'Tipos de campos';

  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private fieldsCustomService: CustomFieldService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getList();
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.fieldsCustomService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<CustomField>) => {
      this.pager = pager;
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  private getList(type?: TypeFieldEnum) {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };

    if (type) {
      this.searchParams['type'] = type;
    }

    this.fieldsCustomService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(
        takeUntil(this.destroy$),
      ).subscribe((pager: Pager<CustomField>) => {
        this.pager = pager;
        this.setupObservableSearch();
      });
  }

  add(): void {
    const dialogRef = this.dialog.open(CustomFieldEditComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  delete(item: CustomField): void {
    const dialogRef = this.dialog.open(AlertModalCheckComponent, {
      width: '600px',
      data: {
        title: 'Tem certeza que deseja remover?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.fieldsCustomService.delete(item._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Campo excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  searchByType(type: TypeFieldEnum): void {
    if (type) {
      this.labelType = type === TypeFieldEnum.TEXT ? 'Texto' :
      type === TypeFieldEnum.NUMBER ? 'Número' : type === TypeFieldEnum.FREETEXT ?
        'Texto livre' : type === TypeFieldEnum.DATE ? 'Data' : '-';
      this.selectedStatus = type;
    } else {
      this.labelType = 'Tipos de campos';
      this.selectedStatus = null;
    }

    this.getList(this.selectedStatus);
  }
}
