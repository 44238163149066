<div class="w-full flex justify-between items-center">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>
  <button [matDialogClose]="" class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300">
    <span class="material-icons">close</span>
  </button>
</div>

<div class="flex flex-col mt-2">
  <h1 class="font-black text-[20px] text-black">Lista de grupos de contato</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Lista de contatos que fazem parte deste grupo</h6>
</div>
<div *ngIf="contactsByGroup.length > 0; else noContacts" class="mt-4">
  <div class="mb-4">
    <input 
      type="text" 
      placeholder="Buscar contato..." 
      class="input-field" 
      [formControl]="searchControl">
  </div>

  <div *ngIf="filteredContacts.length > 0" class="overflow-hidden rounded-lg border border-gray-200 shadow-global">
    <table class="min-w-full bg-white">
      <thead class="bg-gray-100">
        <tr>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Email</th>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Telefone</th>
        </tr>
      </thead>
    </table>

    <div class="overflow-y-auto max-h-[500px]">
      <table class="min-w-full bg-white">
        <tbody>
          <tr *ngFor="let contact of filteredContacts" class="hover:bg-gray-50">
            <td class="py-2 px-4 border-b text-gray-700">{{ contact.name || '-' }}</td>
            <td class="py-2 px-4 border-b text-gray-700">{{ contact.email || '-' }}</td>
            <td class="py-2 px-4 border-b text-gray-700">{{ contact.phone || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <empty-records-component *ngIf="!loadingContent && filteredContacts.length == 0" 
    [message]="'Não existe esse usuário'"
    [subMessage]="'Nenhum contato correspondente à busca atual.'">
  </empty-records-component>
</div>

<ng-template #noContacts>
  <empty-records-component [message]="'Nenhum contato encontrado'" 
    [subMessage]="'Adicionar um novo usuário ao grupo'" 
    >
  </empty-records-component>
</ng-template>
