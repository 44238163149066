import { Component, Injectable, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, CampaignService, Channel, ChannelService, ContactService, DashboardService, Department, DepartmentService, ModalResponseAttendanceComponent, Pager, Team, TeamService, User, UserService } from 'lib-trend-core';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged } from 'rxjs';


interface Message {
  name: string;
  message: string;
}
interface dateDash {
  averageWaitingTime: number,
  averageAttendanceTime: number,
  attendaceTotalTime: number,
  attendanceProgress: number,
  outHours: number,
  attendanceCloused: number,
  attendanceTotalTmfh: number,
  averageAttendanceTmfh: number,
}
interface Campaign {
  channelName: string,
  coust: number,
  totalMessages: number,
  totalRecord: number,
}

interface AttendanceOnlineTypes {
  contact: string;
  user: string;
  channel: string;
  totalChat: number;
  totalMessages: number;
  totalInProgress: number;
  totalClosed: number;
  totalOvertime: number;
  limitAttendancePerAttendant: boolean;
  maxNumberAttendanceAttendant: number;
}

@Component({
  selector: 'dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
@Injectable()
export class DashboardComponent extends AbstractComponent implements OnInit {

  dashboard: dateDash;
  messages: Message[] = [];
  department: Department[];
  userAttendant: User[];
  channelAttendant: Channel[];
  attendanceOn: Pager<AttendanceOnlineTypes> = new Pager<AttendanceOnlineTypes>({ perPage: 10 });
  listTeam: Array<Team> = new Array<Team>();
  msgControl = new FormControl('');
  destroyed$ = new Subject();
  dashByHour = {};
  peakAttendance = [];
  newContact = [];
  newContactWeekend = [];
  campaign: Campaign[] = [];
  pager: Pager<User> = new Pager<User>({ perPage: 10 });


  viewLC: [number, number] = [700, 400]

  viewAP: [number, number] = [1000, 400];
  legendLC = false;
  colorScheme: any = {
    domain: ['#aa98ec', '#4213f6']
  };

  dataByHours: any[] = [];
  dashForm: FormGroup;
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();
  showError = false;

  labelChannel: string = 'Canal';
  labelDepartment: string = 'Departamento';
  labelAttendant: string = 'Atendente';

  constructor(
    injetor: Injector,
    private dashboardService: DashboardService,
    public departmentService: DepartmentService,
    public userSerivce: UserService,
    public contactSerivce: ContactService,
    public channelService: ChannelService,
    private teamService: TeamService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public campaignService: CampaignService,
    private dateAdapter: DateAdapter<Date>
  ) {
    super(injetor);
    this.loadingContent = true;
    this.dateAdapter.setLocale('pt-BR');
  }

  ngOnInit(): void {
    this.createForm();
    this.initializeData();
    this.initFilters();
    this.getByBirthday();

    this.dashForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.onFormChange(val);
    });
  }



  onDateSelected() {
    const dateStart = this.dashForm.get('dateRange.dateStart')?.value;
    const dateEnd = this.dashForm.get('dateRange.dateEnd')?.value;

    if (dateStart && dateEnd) {
      this.picker.close();
    }
  }

  private initFilters() {
    this.getByAllChannel();
    if (this.isAdmin()) {
      this.getByUserAttendant();
      this.getDepartmentByCompany();
    } else if (this.isSupervisor() || this.isAttendant()) {
      this.getListTeam();
    }
  }


  private initializeData() {
    const formsValue = {
      dateStart: this.dashForm.value.dateRange.dateStart,
      dateEnd: this.dashForm.value.dateRange.dateEnd,
      department: this.dashForm.value.department === -1 ? null : this.dashForm.value.department,
      attendant: this.dashForm.value.attendant === -1 ? null : this.dashForm.value.attendant,
      channel: this.dashForm.value.channel === -1 ? null : this.dashForm.value.channel
    };
    this.fetchData(formsValue);
  }

  private fetchData(formsValue?: any) {
    if (!formsValue) {
      formsValue = {
        dateStart: this.dashForm.value.dateRange.dateStart,
        dateEnd: this.dashForm.value.dateRange.dateEnd,
        department: this.dashForm.value.department === -1 ? null : this.dashForm.value.department,
        attendant: this.dashForm.value.attendant === -1 ? null : this.dashForm.value.attendant,
        channel: this.dashForm.value.channel === -1 ? null : this.dashForm.value.channel
      };
    }

    this.getDashboard(formsValue);  
    this.getDashByHours(formsValue);
    this.getPeakAttendance(formsValue);
    this.getNewContact(formsValue);
    this.getDataAnalytics(formsValue);
    this.getAttandanceOnline(formsValue);
    this.dateDashPeakNewContact(formsValue);
  }


  onFormChange(changes: any) {
    if (changes.dateRange.dateStart && changes.dateRange.dateEnd) {
      const formsValue = {
        dateStart: changes.dateRange.dateStart,
        dateEnd: changes.dateRange.dateEnd,
        department: changes.department === -1 ? null : changes.department,
        attendant: changes.attendant === -1 ? null : changes.attendant,
        channel: changes.channel === -1 ? null : changes.channel
      }

      this.getDashboard(formsValue);
      this.getDashByHours(formsValue);
      this.getPeakAttendance(formsValue);
      this.getNewContact(formsValue)
      this.getDataAnalytics(formsValue);
      this.getAttandanceOnline(formsValue);
      this.dateDashPeakNewContact(formsValue);
    }
  }

  sendMessage(): void {
    this.msgControl.setValue('');
  }

  private createForm(): void {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 7);

    this.dashForm = this.fb.group({
      dateRange: this.fb.group({
        dateStart: [yesterday, []],
        dateEnd: [today, []],
      }),
      department: [-1],
      attendant: [-1],
      channel: [-1]
    });
  }


  onPeriodChange(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.dashForm.get('dateRange').patchValue({
          dateStart: new Date(),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.dashForm.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 7)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.dashForm.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 15)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.dashForm.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 30)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.dashForm.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 60)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
    }
  }


  getDashboard({ dateStart, dateEnd, department, attendant, channel }) {
    this.dashboardService.getAttendanceDashboard(dateStart, dateEnd, department, attendant, channel).subscribe({
      next: (dresponseDash) => {
        console.log(dresponseDash)
        this.dashboard = dresponseDash;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getDashByHours({ dateStart, dateEnd, department, attendant, channel }) {
    this.dashboardService.getAttendanceDashByHours(dateStart, dateEnd, department, attendant, channel).subscribe({
      next: (dresponseDash) => {
        this.dataByHours = dresponseDash.map(day => {
          return {
            name: `${day.hour}:00`,
            value: day.totalAttendance
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getDepartmentByCompany() {
    this.departmentService.getList().subscribe({
      next: (dresponseDepart: Department[]) => {
        this.department = dresponseDepart;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getByUserAttendant() {
    this.userSerivce.findByAttendant().subscribe({
      next: (users: User[]) => {
        this.userAttendant = users;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }


  getByAllChannel() {
    this.channelService.getByAllChannel().subscribe({
      next: (channel: Channel[]) => {
        this.channelAttendant = channel;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  private getListTeam(): void {
    this.teamService.getList().subscribe({
      next: (list: Array<Team>) => {
        this.listTeam = list
          .filter((team: Team) => {
            const userId = this.getCurrentUserUser()._id;
            const isAgent = team.agents.some((agent: User) => agent._id === userId);
            const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === userId);
            return isAgent || isSupervisor;
          })
          .filter((team: Team) => team.department && team.department.removed !== true);
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os times da sua empresa. Tente novamente mais tarde.'),
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getByBirthday();
  }

  loadPageOnline(page: number) {
    this.pager.page = page;
    const formsValue = {
      dateStart: this.dashForm.value.dateRange.dateStart,
      dateEnd: this.dashForm.value.dateRange.dateEnd,
      department: this.dashForm.value.department === -1 ? null : this.dashForm.value.department,
      attendant: this.dashForm.value.attendant === -1 ? null : this.dashForm.value.attendant,
      channel: this.dashForm.value.channel === -1 ? null : this.dashForm.value.channel,
    };
    this.getAttandanceOnline(formsValue);
  }

  getByBirthday() {
    this.contactSerivce.getByBirthday(this.pager.page, this.pager.perPage).subscribe({
      next: (users: any) => {
        this.pager = users;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }


  getPeakAttendance({ dateStart, dateEnd, department, attendant, channel }) {
    this.dashboardService.getPeakAttendance(dateStart, dateEnd, department, attendant, channel).subscribe({
      next: (peak) => {
        this.peakAttendance = peak.map(day => {
          return {
            name: day.name,
            value: day.count
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  dateDashPeakNewContact({ dateStart, dateEnd, department, attendant, channel }) {
    this.dashboardService.dateDashPeakNewContact(dateStart, dateEnd, department, attendant, channel).subscribe({
      next: (contact) => {
        this.newContactWeekend = contact.map(contacts => {
          return {
            name: contacts.name,
            value: contacts.count
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getNewContact({ dateStart, dateEnd, department, attendant, channel }) {
    this.dashboardService.getNewContact(dateStart, dateEnd, department, attendant, channel).subscribe({
      next: (contact) => {
        this.newContact = contact.map(contacts => {
          return {
            name: contacts.channelName,
            value: contacts.count
          }
        });
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getAttandanceOnline({ dateStart, dateEnd, department, attendant, channel }) {
    this.loadingSpinnerSubject.next(true)
    this.dashboardService.dateDashAttandanceOnline(dateStart, dateEnd, department, attendant, channel, this.pager.page, this.pager.perPage).subscribe({
      next: (attandenceOnline) => {
        this.attendanceOn = attandenceOnline
      },
      complete: () => this.loadingSpinnerSubject.next(false),
      error: (err) => this.alertService.error(err.error.message)
    })
  }


  getDataAnalytics(query: any): void {
    this.campaignService.getDataAnalyticsDash(query).subscribe({
      next: (value: Array<any>) => {
        this.campaign = value;
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os dados das campanhas. Tente novamente mais tarde.'),
    });
  }

  openModalAttendance(status: string): void {
    const refBottom = this.dialog.open(ModalResponseAttendanceComponent, {
      width: '50rem', height: '40rem', data: {
        status: status,
        dateStart: this.dashForm.value.dateRange.dateStart,
        dateEnd: this.dashForm.value.dateRange.dateEnd,
        department: this.dashForm.value.department === -1 ? null : this.dashForm.value.department,
        attendant: this.dashForm.value.attendant === -1 ? null : this.dashForm.value.attendant,
        channel: this.dashForm.value.channel === -1 ? null : this.dashForm.value.channel
      }
    });

    refBottom.afterClosed();
  };

  transform(birthDate: Date | string): number {
    if (!birthDate) return 0;
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }

  calculatePercentage(limitAttendancePerAttendant: boolean, limite: number, totalAtendimento: number): number {
    if (limite === 0 || !limitAttendancePerAttendant) {
      return 0;
    }
    const percentage = (totalAtendimento / limite) * 100;
    const value = Math.min(percentage, 100);
    if (value >= 50 && value < 90) {
      return 1
    }
    if (value >= 90) {
      return 2
    }
    return 0
  }

  formatPercentage(value: number): string {
    return `${value.toFixed(1)}%`; 
  }

  onChannelSelect(value: any, label: string = 'Canal'): void {
    this.dashForm.get('channel')?.setValue(value);
    this.labelChannel = label;
  }
  
  onDepartmentSelect(value: any, label: string = 'Departamento'): void {
    this.dashForm.get('department')?.setValue(value);
    this.labelDepartment = label;
  }

  onAttendantSelect(value: any, label: string = 'Atendante'): void {
    this.dashForm.get('attendant')?.setValue(value);
    this.labelAttendant = label;
  }

}
