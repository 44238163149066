import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { TagComponent } from './tag.component';
import { TagEditComponent } from './tag-edit/tag-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CustomFieldModule } from '../custom-field/custom-field.module';

@NgModule({
  imports: [
    SharedModule,
    ColorPickerModule,
    CustomFieldModule,
  ],
  declarations: [
    TagComponent,
    TagEditComponent,
  ],
  exports: [
    TagComponent,
    TagEditComponent
  ]
})
export class TagModule { }
