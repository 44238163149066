import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractComponent, MustMatch, UserService } from 'lib-trend-core';
import { timer } from 'rxjs';

@Component({
  templateUrl: 'update-password.component.html',
})
export class UpdatePasswordComponent extends AbstractComponent implements OnInit {

  hideAllPasswords: boolean = true;
  userId: string;

  constructor(
    injector: Injector,
    private userService : UserService
  ) {
    super(injector);
    this.setupForm();
  }

  ngOnInit() {
    this.getUserId();
  }

  setupForm(): void {
    this.formGroup = this.formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmedPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validators: [MustMatch('newPassword', 'confirmedPassword')] });
  }

  getUserId(): void {
    this.userId = this.getIDCurrentUser();
    if (!this.userId) {
      this.alertService.error('Usuário não está logado.');
    }
  }

  save(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    const currentPassword = this.formGroup.get('currentPassword').value;
    const newPassword = this.formGroup.get('newPassword').value;
    const confirmedNewPassword = this.formGroup.get('confirmedPassword').value;

    this.userService.updatePassword(super.getIDCurrentUser(),{newPassword, confirmedNewPassword, currentPassword} ).subscribe({
      next: () => {
        this.alertService.success('Sua senha foi atualizada com sucesso!');
        timer(5000).subscribe(() => {
          this.loading = false;
          window.location.href = '/';
        });
      },
      error: err => {
        this.alertService.error(err.error.message || 'Erro ao atualizar senha.');
        this.loading = false;
      }
    });
  }

  togglePasswordVisibility(): void {
    this.hideAllPasswords = !this.hideAllPasswords;
  }
}
